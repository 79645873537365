import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import styled, { withTheme, ThemeContext } from "styled-components"
import primaryLogo from "../images/logo/bt_logo.jpg"
import primaryLogoTrans from "../images/logo/bt_logo_transparent.png"
import bgHero from "../images/background/holding_bg_darkened_2000.jpg"

const MainHeader = styled.header`
  /*background: #ef017c;*/
  position: relative;
  z-index: 100;
  background: #fff;

  .inner-container {
  }
`

const TopBar = styled.div``

const Logo = styled.img`
  display: block;
  max-width: 200px;
  margin-right: 25px;
  margin-bottom: 0;

  @media (max-width: 880px) {
    max-width: 140px;
    margin: 0;
  }
`

const HeroBlock = styled.div`
  background: #092234;
  background-image: url(${bgHero});
  background-size: cover;
  background-position: center bottom;
  /*background: #7f00ff; /* fallback for old browsers */
  /*background: -webkit-linear-gradient(
    to right,
    #e100ff,
    #7f00ff
  ); /* Chrome 10-25, Safari 5.1-6 */
  /*background: linear-gradient(
    to right,
    #e100ff,
    #7f00ff
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  padding: 80px 0;

  @media (max-width: 880px) {
    padding: 40px 0;
  }

  .inner-container {
    display: flex;
    align-items: center;

    @media (max-width: 880px) {
      flex-direction: column;
    }
  }

  .hero-block-content {
    @media (max-width: 880px) {
      text-align: center;
      margin-top: 40px;
    }
  }

  h2 {
    font-size: 2.4rem;
    color: #fff;
    @media (max-width: 880px) {
      font-size: 1.8rem;
      line-height: 2.4rem;
    }
  }

  p {
    color: #fff;
    font-size: 1.2rem;
    line-height: 2rem;

    &:last-of-type {
      margin-bottom: 0;
    }

    @media (max-width: 880px) {
      font-size: 1rem;
      line-height: 1.5rem;
    }
  }
`

const Header = ({ siteTitle }) => (
  <MainHeader>
    {/*<TopBar>
      <div className="inner-container">
        <Logo src={primaryLogo} />
      </div>
    </TopBar>*/}
    <HeroBlock>
      <div className="inner-container">
        <Logo src={primaryLogoTrans} />
        <div className="hero-block-content">
          <h2>Managed Tech Solutions - On Your Terms</h2>
          <p>
            We create bespoke tech solutions to suit your requirements on a
            choice of flexible monthly payment plans. Working only with best in
            class vendors we’re agile and deliver a fast, reliable and
            centralised experience.
          </p>
        </div>
      </div>
    </HeroBlock>
  </MainHeader>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
