/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import { styled, ThemeProvider } from "styled-components"

import Header from "./header"
import "./layout.css"

const theme = {
  purple: "#da0bf7",
  blue: "#24cece",
  globalMaxWidth: "1200px",
}

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <ThemeProvider theme={theme}>
      <Header siteTitle={data.site.siteMetadata.title} />

      <main>{children}</main>
      <footer
        style={{
          fontSize: "0.8rem",
          textAlign: "center",
        }}
      >
        <div
          style={{ padding: "10px 0", borderTop: "1px solid #d9d9d9" }}
          className="inner-container"
        >
          <b>New Website Coming Soon</b>
        </div>
        <div
          style={{ padding: "10px 0", borderTop: "1px solid #d9d9d9" }}
          className="inner-container"
        >
          © {new Date().getFullYear()}
          {` `}|{` `}
          Logged In
        </div>
      </footer>
    </ThemeProvider>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
